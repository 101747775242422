import React from 'react';
import { BrandContext, ConfigContext, LoginForm, OtkIdentityService } from '@edgenuity/cns-ui';
import LoginFormValuesInterface from '@edgenuity/cns-ui/shared/interfaces/login-form-values.interface';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../shared/enums/routes.enum';
import axios, { AxiosResponse } from 'axios';

export default function Login() {
	const [errorState, setErrorState] = React.useState<string | undefined>();
	const configState = React.useContext(ConfigContext);
	const brandState = React.useContext(BrandContext);
	const navigate = useNavigate();

	async function onSubmit(formValues: LoginFormValuesInterface) {
		setErrorState(undefined);
		if (configState && brandState) {
			const identityService = new OtkIdentityService(configState.service);
			try {
				const response = await identityService.login(
					formValues.usernameOrEmail,
					formValues.password,
					brandState.brand
				);
				identityService.authService.store({ token: response.access_token });
				navigate('/' + RoutesEnum.LMS_LAUNCH, { state: response });
			} catch (e) {
				if (axios.isAxiosError(e) && e?.response?.status === 418) {
					const response = e.response as AxiosResponse;
					const lockoutDuration = response.data.lockoutDuration;
					setErrorState(
						`The username or password you entered is incorrect. This account has been temporarily locked. Login may be attempted again in ${lockoutDuration} seconds.`
					);
				} else {
					setErrorState('Your login or password is incorrect, please try again');
				}
			}
		}
	}

	return (
		<LoginForm
			headingText="Welcome Back!"
			forgotPasswordLink="/forgot-password"
			signUpLink="/signup"
			errorMessage={errorState}
			onSubmit={onSubmit}
		/>
	);
}
